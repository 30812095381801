/* eslint-disable max-lines */
import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'

import { Layout, PostCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ location, pageContext }) => (
    <>
        <MetaData location={location} />
        <Layout isHome={true}>
            <section className="contentWrapper firstLoad section-1 bg-right">
                <div className="container">
                    <h1 className="h1">Lose weight, get strong, and stay healthy with personalized online 1-on-1 Workout, Nutrition, and Behavior Coaching</h1>
                    <div className="contentFocus">
                        <p>So you want to add muscle, feel more confident for a vacation, change eating habits, or achieve a unique fitness and nutrition goal - we can get you there together.</p>
                        <p>Work together with me over Email, text, or a 30-minute phone call to create a plan with long lasting change unique to you, your goals and your lifestyle.</p>
                        <h2 className="h2">Schedule your free consultation</h2>
                        <div className="contentCTAContainer">
                            <Link className="button-primary-colors button-size-large" to="/">Start Now</Link>
                            <Link className="button-tertiary-colors button-size-large" to="/">Learn More</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contentWrapper section-2 contentSection bg-left">
                <div className="container">
                    <h2 className="h1">Focus on activities you love, food you enjoy, and doing more of what makes you feel alive</h2>
                    <div className="contentFocus">
                        <p>Making change isn’t always easy, but we’ll work together to overcome obstacles and challenges that have stopped you in the past. We’ll create your workout plans with an understanding of the exercises you love and avoid those that will make you quit.</p>
                        <p>The change you’re looking for should be based in a foundation of love and respect for your body. Your workouts, nutrition, and coaching will all reflect that love.</p>
                        <h3 className="h3">Download free examples of beginner workout plans:</h3>
                        <div className="contentCTAContainer">
                            <Link className="button-secondary-colors button-size-normal" to="/">Start Today</Link>
                            <Link className="button-secondary-colors button-size-normal" to="/">Learn More</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contentWrapper section-3 contentSection bg-right">
                <div className="container">
                    <h2 className="h1">Boutique training and coaching plans designed to your personal level of fitness, lifestyle, and health history</h2>
                    <div className="contentFocus">
                        <p>No two people are alike and our plans reflect that. Your workouts, nutrition, and interactions with your trainer will all be fit to your lifestyle with success in mind.</p>
                        <p>Your plans may include 5-days in a gym, more play with your four-legged friends, or 15 minute workouts a few times a week. Most importantly, you’ll begin to build effective habits to rely on as the foundation of your health plan.</p>
                        <h3 className="h3">Download free habit help guides:</h3>
                        <div className="contentCTAContainer">
                            <Link className="button-secondary-colors button-size-normal" to="/">Start Today</Link>
                            <Link className="button-secondary-colors button-size-normal" to="/">Learn More</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contentWrapper section-4 contentSection bg-left">
                <div className="container">
                    <h2 className="h1">Messaging and progress tracking where, when, and how you’re most comfortable fitting them into your day</h2>
                    <div className="contentFocus">
                        <p>Your coach is here to support you and keep you on a healthy, productive, and positive path. This means we’re flexible to how you prefer to communicate. Text, email, phone, video chat, Facebook Messenger, WhatsApp, or any other platform you prefer is where we will meet you.</p>
                        <p>You will also have direct access to an easy to use, app-based platform that will always have your next workouts and tasks with you wherever you are.</p>
                        <h3 className="h3">Schedule your free consultation</h3>
                        <div className="contentCTAContainer">
                            <Link className="button-secondary-colors button-size-normal" to="/">Start Today</Link>
                            <Link className="button-secondary-colors button-size-normal" to="/">Learn More</Link>
                        </div>
                    </div>
                </div>
            </section>
            <section className="contentWrapper section-5 contentSection bg-right">
                <div className="container">
                    <h2 className="h1">Get started in no-time knowing you’ll always have a personal trainer and coach at your back.</h2>
                    <div className="contentFocus">
                        <p>Your success, safety, and satisfaction are our goals. </p>
                        <h3 className="h2">Get Started Now</h3>
                        <div className="contentCTAContainer">
                            <Link className="button-primary-colors button-size-normal" to="/">Schedule Your Free Consultation Today</Link>
                        </div>
                    </div>
                    <form name="testform" netlify data-netlify-honeypot="bot-field">
  <p>
    <label>Name <input type="text" name="name" /></label>
  </p>
  <p>
    <label>Email <input type="email" name="email" /></label>
  </p>
  <input type="hidden" name="form-name" value="testform" />
  <p>
    <button type="submit">Send</button>
  </p>
</form>
                </div>
            </section>
        </Layout>
    </>
)

Index.propTypes = {
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

